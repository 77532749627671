<template>
    <div>
        <div v-for="image in inputVal.media"
             :key="image.id"
        >
            <a target="_blank" :href="mediaUrl(image.id)"
               :data-url="getImageBlobUrl(image.id)"
            >
                <img style="width:100px" v-if="images[image.id]" :src="images[image.id]">
            </a>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'MediaColumn',
    props: {
        value: [Object],
        savePosition: [Function]
    },
    data() {
        return {
            images:{}
        }
    },
    methods: {
        mediaUrl(id) {
            return this.$root.getDocumentUrl(id, 'media')
        },
        getImageBlobUrl(name) {
            let url = null
            if (typeof this.images[name] == 'undefined') {
                axios.get(window.apiUrl + '/download/media/' + name)
                    .then(response => {
                        function s2ab(s) {
                            let buf = new ArrayBuffer(s.length);
                            let view = new Uint8Array(buf);
                            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                            return buf;
                        }

                        const blob = new Blob([s2ab(atob(response.data.content))], {type: response.data.type});
                        this.$set(this.images, name, window.URL.createObjectURL(blob))
                    })
            }
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>